<template>
<div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="performaxial()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/auxiliary_equipement/perform_machine/axial.png"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Axials
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="performradials()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/auxiliary_equipement/perform_machine/radial.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Radials
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="pcboardseparators()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/auxiliary_equipement/perform_machine/board.webp"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Pc Board Separators 
              </h4>
            </div>
          </div>
          </a>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="speciallinemachines()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/auxiliary_equipement/perform_machine/special_line.webp"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Special Line Machines 
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="countersandscales()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/auxiliary_equipement/perform_machine/counter.webp"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Counters And Scales
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="performotherproducts()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/auxiliary_equipement/perform_machine/other.webp"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Other Products
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        </div>
      </div>
         <Footer />
        </div>
</template>
<script>

import Header from '../../../components/Header.vue';

import Footer from '../../../components/Footer.vue';

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
  
    Footer
    
   
  },
  methods: {
       performaxial(){
        window.location.href = "https://www.olamef.net/en-gb/axials/";

    },
    performradials(){
      window.location.href = "https://www.olamef.net/en-gb/radials/";

    },
    pcboardseparators(){
      window.location.href = "https://www.olamef.net/en-gb/pc-board-separators/";

    },
    speciallinemachines(){
      window.location.href = "https://www.olamef.net/en-gb/special-line-machines/";
   
    },
    countersandscales(){
      window.location.href = "https://www.olamef.net/en-gb/counters-and-scales/";

    },
    performotherproducts(){
      window.location.href = "https://www.olamef.net/en-gb/other-products/";
   
    },
  },
  
};
</script>